<template>
  <div>
    <el-row class="dash-board">
      <el-col :span="6">
        <p class="in1">培训班名称:</p>
        <el-select style="width: 190px" class="e-inps" placeholder="请选择班级" v-model="className" filterable clearable>
          <el-option v-for="(item,index) in classData"
                    :key="index"
                    :label="item.className"
                    :value="item.classId"/>
        </el-select>
        <!-- <el-input style="width: 200px" placeholder="输入培训班名称" class="e-inps" v-model="className"></el-input> -->
        <div class="select-btn-box">
          <el-button type="primary" @click="schedulelist" v-permission="['comment:select']" class="">查询</el-button>
          <el-button type="warning" @click="reset" class="reset">重置</el-button>
        </div>
      </el-col>
    </el-row>
    <el-row class="bg">
      <el-table
        :data="tableData"
        style="width: 100%;">
        <el-table-column
          label="培训班名称"
          align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.className }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="开班地点"
          align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.signLocation }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="老师名称"
          align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.teacher }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="课程平均打分"
          align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.courseGrade }} 分</span>
          </template>
        </el-table-column>
        <el-table-column
          label="老师平均打分"
          align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.teacherGrade }} 分</span>
          </template>
        </el-table-column>
        <el-table-column
          label="班主任平均打分"
          align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.bzrGrade }} 分</span>
          </template>
        </el-table-column>
         <el-table-column
          label="课程设计与课程目标"
          align="center">
          <template slot-scope="scope">
            <span>{{scope.row.courseTarget}} 分</span>
            <!-- <span v-if="scope.row.courseTarget==1">很好</span>
            <span v-if="scope.row.courseTarget==2">好</span>
            <span v-if="scope.row.courseTarget==3">一般</span>
            <span v-if="scope.row.courseTarget==4">差</span>
            <span v-if="scope.row.courseTarget==5">很差</span> -->
          </template>
        </el-table-column>
         <el-table-column
          label="授课技能与课堂气氛"
          align="center">
          <template slot-scope="scope">
            <span>{{scope.row.courseAtmosphere}} 分</span>
            <!-- <span v-if="scope.row.courseAtmosphere==1">很好</span>
            <span v-if="scope.row.courseAtmosphere==2">好</span>
            <span v-if="scope.row.courseAtmosphere==3">一般</span>
            <span v-if="scope.row.courseAtmosphere==4">差</span>
            <span v-if="scope.row.courseAtmosphere==5">很差</span> -->
          </template>
        </el-table-column>
         <el-table-column
          label="课堂案例中的实用性"
          align="center">
          <template slot-scope="scope">
            <span>{{scope.row.coursePracticality}} 分</span>
            <!-- <span v-if="scope.row.coursePracticality==1">很好</span>
            <span v-if="scope.row.coursePracticality==2">好</span>
            <span v-if="scope.row.coursePracticality==3">一般</span>
            <span v-if="scope.row.coursePracticality==4">差</span>
            <span v-if="scope.row.coursePracticality==5">很差</span> -->
          </template>
        </el-table-column>
         <el-table-column
          label="社会实践中的指导性"
          align="center">
          <template slot-scope="scope">
            <span>{{scope.row.courseGuiding}} 分</span>
            <!-- <span v-if="scope.row.courseGuiding==1">很好</span>
            <span v-if="scope.row.courseGuiding==2">好</span>
            <span v-if="scope.row.courseGuiding==3">一般</span>
            <span v-if="scope.row.courseGuiding==4">差</span>
            <span v-if="scope.row.courseGuiding==5">很差</span> -->
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top:20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="total,sizes,  prev, pager, next, jumper"
        :total="total"
        class="pag"
      >
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
  import { comment } from '@/api/training.js'
  import { getAllClassByMoHu } from '@/api/user.js'
  export default {
    name: 'Schedule',
    data () {
      return {
        classData: [], // 存储班级列表信息
        tableData: [],
        className: '',
        total: 0,
        pageNum: 1,
        pageSize: 10
      }
    },
    mounted () {
      this.schedulelist()
      this.findClass()
    },
    methods: {
      // 获取班级列表
      findClass() {
        getAllClassByMoHu().then(response => {
          this.classData = response.data.data.rows
        })
      },
      schedulelist () {
        const query = {
          classId: this.className,
          userName: this.$store.getters.username,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        comment(query).then(resp => {
          if (resp.data.code === '200') {
            this.tableData = resp.data.data.rows
            this.total = resp.data.data.total // 查询总条数
          }
        })
      },
      reset() {
        this.className = ''
      },
      handleSizeChange(e) {
        this.pageSize = e
        this.schedulelist()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.schedulelist()
      }
    }
  }
</script>

<style scoped>
  .in1{font-size: 13px; position: absolute; top: 10px; left: 17px}
  .e-inps{position: absolute; left: 100px; top: 14px}
  .dash-board {display: flex; background-color: white; height: 60px; padding: 10px 0;}
  .bg{margin-top: 15px;  padding: 20px; background-color: white;}
  .inquire{margin-right: 20px;}
  .select-btn-box{position: absolute;left: 310px;top: 15px;}
  a{margin:auto; width: 70px; line-height: 28px; display: block; border-radius:4px;
    color: #FFF; background-color: #409EFF; border-color: #409EFF;text-align:center;
    font-size:12px; text-decoration:none; cursor:pointer;}
  a:hover{color: #FFF; background-color: #70b3f9; border-color: #70b3f9; text-decoration:none; }
</style>
